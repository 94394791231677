import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'

const BASE_API = process.env.BASE_API
const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class EmailVerifiedPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
    this.triggercompletesignup = this.triggercompletesignup.bind(this);
  }
  triggercompletesignup = async (event) => {    
    let headers = new Headers()
    headers.append('Content-Type', 'text/html')
    headers.append('Origin', '*')
    var requestConfig = {
      method: 'POST',
      body: JSON.stringify("CompleteSetup"),
      mode: 'cors',
      cache: 'no-cache',
      headers: headers,
    }
    fetch(BASE_API + 'usermgmt_completesignup', requestConfig)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)        
      })
      .catch((error) => {
        console.log(error)
      })
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
    setTimeout(() => {
      this.triggercompletesignup()
    }, 500)
    
  }
  
  

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Thank You for verifying'
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }
    console.log(this.props)

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0} className="verifycode_container">
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                <div style={{ minHeight: '40vmin' }}>
                  Thankyou for verifying your email. You will soon receive an email with information about your EdgeForecaster subscription.
                  <br/><br/>
                  Welcome to the <span style={{color:"#4648ff", fontWeight:"bold"}}>EDGE</span> revolution.
                </div>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(EmailVerifiedPage)
export const EmailVerifiedPageQuery = graphql`
  query EmailVerifiedPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
